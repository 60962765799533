.choosed-table_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.1);
    span {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: $primaryColor;
    }
    .choosedtable__btn {
        padding: 6px;
        background: #f1f1f1;
        border-radius: 87px;
        button {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            color: #646268;
            border: none;
        }
    }
}