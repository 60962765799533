.l-topcardwrapper {
    background-color: #f9f8f4;
    padding: 60px 16px;
    .lcard_content {
        h2 {
            text-align: center;
        }
        p {
            margin-bottom: 32px;
        }
    }
    .lcard_box {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        .appCard {
            background: #ffffff;
            border-left: 2px solid #171717;
            border-top: 2px solid #171717;
            border-right: 5px solid #171717;
            border-bottom: 5px solid #171717;
            border-radius: 25px;
            min-height: 370px;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .landingtopcard {
            padding: 30px;
            // min-height: 340px;
        }
        .landingtopcard p {
            font-family: $hellix;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            // text-align: justify;
            letter-spacing: 0.5px;
            color: #4a4a4a;
            margin-bottom: 35px;
        }
        .card_icon {
            margin-bottom: 35px;
        }
        .landingCardII img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        .landingCardII p {
            font-family: $hellix;
            padding: 30px;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: -0.01em;
            color: #171717;
            margin: 0px;
        }
    }
}

@media only screen and (min-width: 860px) {
    .l-topcardwrapper {
        position: relative;
        padding: 40px;
        .imageiconIII {
            position: absolute;
            left: 0px;
            top: -40px;
        }
        .imageiconIV {
            position: absolute;
            right: 0px;
            bottom: -40px;
        }
        .lcard_content {
            h2 {
                width: 50%;
                margin: auto;
            }
            p {
                width: 60%;
                margin: auto;
                // text-align: center;
                margin-bottom: 64px;
                margin-top: 16px;
            }
        }
        .lcard_box {
            .appCard {
                width: calc(33.33% - 11px);
                // padding: 16px;
            }
            display: flex;
            flex-wrap: wrap;
            // padding: 40px;
            gap: 16px;
            background-color: #f9f8f4;
            .landingtopcard {
                min-height: 348px;
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .landingtopcard {
        min-height: 414px !important;
    }
    .l-topcardwrapper {
        padding: 60px 80px;
    }
}

@media only screen and (min-width: 1280px) {
    .landingtopcard {
        min-height: 362px !important;
    }
    .l-topcardwrapper {
        padding: 80px 100px;
    }
}