.landing-package {
    border: 2px solid #171717;
    border-right: 4px solid #171717;
    border-bottom: 4px solid #171717;
    border-radius: 15px;
    padding: 24px;
    h4 {
        font-family: $hellix;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: -0.01em;
        color: #171717;
    }
    p {
        font-family: $hellix;
        font-weight: 400;
        font-size: 15px;
        line-height: 26px;
        color: #4a4a4a;
    }
    .starting_at {
        font-family: $hellix;
        font-weight: 400;
        font-size: 13px;
        line-height: 26px;
        color: #4a4a4a;
    }
    .landingpackage_subheading {
        font-family: $hellix;
        margin: 16px 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #171717;
    }
    .packagelist_wrapper {
        .packagelist_content {
            display: flex;
            margin-bottom: 16px;
            gap: 12px;
            font-family: $hellix;
            &div:nth-child(2) {
                font-family: $hellix;
                font-weight: 400;
                font-size: 15px;
                line-height: 26px;
                color: #4a4a4a;
            }
        }
        .landingpackage_btn {
            margin-top: 60px;
        }
    }
}