.landingPage_wrapper {
    // max-width: 1440px;
    // margin: auto;
    // background-color: #f9f8f4;
    .landingPackage_section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 60px 16px;
        .landing_headingII {
            font-family: $hellix;
            font-weight: 700;
            text-align: center;
            margin-bottom: 32px;
        }
        .landingpackage_wrapper {
            display: flex;
            flex-direction: column;
            gap: 40px;
        }
    }
    @media only screen and (min-width: 860px) {
        .landingPackage_section {
            padding: 60px 40px;
            position: relative;
            .imageiconI {
                position: absolute;
            }
            .landing_headingII {
                width: 70%;
            }
            .landingpackage_wrapper {
                display: flex;
                flex-direction: row;
                gap: 30px;
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        .landingPackage_section {
            padding: 120px 80px;
        }
    }
    @media only screen and (min-width: 1280px) {
        .landingPackage_section {
            padding: 120px 100px;
        }
    }
}