.detailmenu_wrapper {
    position: relative;
    .detailmenu__image {
        img {
            width: 100%;
            height: 240px;
            object-fit: cover;
        }
    }
    // .detailmenu__arrow {
    //     width: 32px;
    //     height: 32px;
    //     border-radius: 50%;
    //     background-color: #fafafa;
    //     position: absolute;
    //     top: 16px;
    //     left: 16px;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     .arrow-left {
    //         color: $primaryColor;
    //         font-size: 30px;
    //         background-color: $secondaryColor;
    //         border-radius: 50%;
    //         z-index: 1;
    //     }
    // }
    .sold {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 240px;
        top: 0px;
        color: $secondaryColor;
        background-color: #75767550;
        font-weight: 700;
        font-size: 20px;
        // opacity: 0.6px;
    }
    .detailitem_container {
        width: 100%;
        position: absolute;
        top: 220px;
        background-color: #fafafa;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        min-height: calc(100vh - 220px);
        .detailitem__content {
            padding: 16px;
            .menu_status {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $primaryColor;
                width: 74px;
                padding: 4px;
                text-transform: uppercase;
                border-radius: 32px;
                color: $secondaryColor;
                margin-bottom: 8px;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
            }
            .fire {
                background-color: $primaryColor;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 6px;
                .fire-icon {
                    color: #fafafa !important;
                }
            }
            h4 {
                font-weight: 600;
                font-size: 16px;
                line-height: 21px;
                color: #211e27;
                width: 100%;
                height: auto;
                overflow-wrap: break-word;
            }
            .detail__desc {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #646268;
                margin-top: 6px;
                overflow-wrap: break-word;
            }
            .detail_price_GVD {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 24px;
                .detail__price {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 13px;
                    line-height: 16px;
                    color: $primaryColor;
                    margin: 0px;
                }
            }
        }
        .choice_container {
            background-color: #f1f1f1;
            display: flex;
            flex-direction: column;
            padding: 16px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #211e27;
        }
        .choice_container span:first-child {
            color: #cf1111;
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #211e27;
            // margin-bottom: 6px;
        }
        .choice_container span:nth-child(2) {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: #f91818;
        }
        .item__toppings {
            background-color: $secondaryColor;
            padding-bottom: 76px;
            // margin-bottom: 80px;
            .itemtopping__content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 16px;
                border-top: 1px solid #f1f1f1;
                // border-bottom: 1px solid #f1f1f1;
            }
            .itemtopping__content span:nth-child(1) {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #646268;
            }
            .itemtopping__content span:nth-child(2) {
                font-weight: 400;
                font-size: 10px;
                line-height: 12px;
                color: #646268;
                margin-left: 5px;
            }
            .choiceII {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid $secondaryColor;
            }
            .detail__checkbox {
                display: block;
                position: relative;
                padding-left: 35px;
                margin-bottom: 12px;
                cursor: pointer;
                font-size: 22px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
            /* Hide the browser's default checkbox */
            .detail__checkbox input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }
            .checkmark {
                position: absolute;
                top: -6px;
                right: 0px;
                height: 24px;
                width: 24px;
                border-radius: 50%;
                border: 1px solid #bcbcbe;
                background-color: $secondaryColor;
            }
            .detail__checkbox:focus input~.checkmark {
                background-color: $primaryColor;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .detail__checkbox input:checked~.checkmark {
                background-color: $primaryColor;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }
            .detail__checkbox input:checked~.checkmark:after {
                display: block;
            }
            .detail__checkbox .checkmark:after {
                left: 8px;
                top: 5px;
                width: 5px;
                height: 10px;
                border: solid white;
                display: flex;
                justify-content: center;
                align-items: center;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }
    .detailitem_container.inactive {
        top: 0px;
        padding-top: 48px;
        border-radius: 0px;
    }
    .detailmenu_addorder {
        display: flex;
        padding: 16px;
        gap: 16px;
        position: fixed;
        bottom: 0px;
        background-color: $secondaryColor;
        box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1);
        width: 100%;
        .incdec__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            // padding: 10px;
            border: 1px solid #646268;
            border-radius: 8px;
            width: 30%;
        }
        .incdec__btn div {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $primaryColor;
        }
        .incdec_icon {
            color: $primaryColor;
            font-size: 16px;
        }
        .incdec__btn div:first-child {
            padding: 10px 5px;
            width: 30%;
            height: 100%;
        }
        .incdec__btn div:nth-child(2) {
            padding: 6px 5px;
            font-size: 16px;
            width: 30%;
            height: 100%;
        }
        .incdec__btn div:last-child {
            background-color: #f1f1f1;
            padding: 10px 5px;
            width: 40%;
            height: 100%;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
    .detailmenu_addorder button {
        width: 70%;
        background-color: $primaryColor;
        border-radius: 8px;
        color: #fafafa;
        border: none;
        padding: 12px 34px;
    }
    .btn-opacityactive {
        opacity: 0.6;
    }
}

.menu__GVD {
    display: flex;
    column-gap: 8px;
    span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #646268;
    }
}

.detailmenu__arrow {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #fafafa;
    position: absolute;
    top: 16px;
    left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    .arrow-left {
        color: $primaryColor;
        font-size: 30px;
        background-color: $secondaryColor;
        border-radius: 50%;
        z-index: 1;
    }
}