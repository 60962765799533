.landingCard {
    .landing-cardimg {
        border-radius: 15px;
        margin-bottom: 16px;
        img {
            width: 100%;
            height: 100%;
            border-right: 7px solid #171717;
            border-bottom: 7px solid #171717;
            border-top: 3px solid #171717;
            border-left: 3px solid #171717;
            border-radius: 15px;
        }
    }
    .landing-cardcontent {
        .landingcard_content-2 {
            display: flex;
            margin-top: 24px;
            gap: 12px;
            .l-inner-content {
                .landingcard-coheading {
                    font-family: $hellix;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 25px;
                    color: #171717;
                }
                .landingcard_paragraph {
                    font-family: $hellix;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 25px;
                    color: #171717;
                }
            }
        }
    }
}

@media only screen and (min-width: 990px) {
    .landingCard {
        display: flex;
        align-items: center;
        gap: 30px;
        .landing-cardimg {
            width: 45%;
            height: 423px;
            margin: 0px;
        }
    }
    .landing-cardcontent {
        width: 55%;
    }
    .landingCard-reverse {
        flex-direction: row-reverse;
    }
}

@media only screen and (min-width: 1280px) {
    .landingCard {
        gap: 60px;
    }
}