.button_data {
    button {
        background-color: #fafafa;
        border: 1px solid black;
    }
}

.btn {
    font-family: $hellix;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    padding: 16px;
    cursor: pointer;
    text-align: center;
}

.btn-outlined {
    background-color: $primaryColor;
    border-radius: 8px;
    color: $secondaryColor;
    border: none;
}

.btn-primary {
    background-color: $primaryColor;
    width: 100%;
    border-radius: 8px;
    border: none;
    color: $secondaryColor;
    font-weight: 600;
}

.btn-secondary {
    border: 1px solid $primaryColor;
    background-color: $secondaryColor;
    color: $primaryColor;
    width: 100%;
    border-radius: 8px;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
}

.btn-white-outlined {
    border: 1px solid $secondaryColor;
    background-color: $primaryColor;
    color: $secondaryColor;
    width: 100%;
    border-radius: 8px;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
}

.btn-black-outlined {
    border: 1px solid $primaryColor;
    background-color: $secondaryColor;
    color: $primaryColor;
    border-radius: 8px;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
}

.btn-smbutton {
    background: #171717;
    border-radius: 10px;
    color: #ffffff;
    border: 2px solid #171717;
    padding: 15px 32px;
    position: relative;
    overflow: hidden;
    z-index: 10;
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: scale(0);
        transition: transform 0.3s ease-in;
        // mix-blend-mode: difference;
        background: radial-gradient( circle at center, rgb(255, 255, 255) 28%, rgb(0, 0, 0) 28%, rgb(0, 0, 0) 100%);
        z-index: -1;
    }
    &:hover {
        color: rgb(0, 0, 0);
        &:after {
            transform: scale(5);
        }
    }
}

.btn-lgbutton {
    background: #171717;
    border-radius: 10px;
    color: #ffffff;
    border: 2px solid #171717;
    position: relative;
    padding: 15px 32px;
    width: 100%;
    overflow: hidden;
    z-index: 10;
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: scale(0);
        transition: transform 0.3s ease-in;
        // mix-blend-mode: difference;
        background: radial-gradient( circle at center, rgb(255, 255, 255) 28%, rgb(0, 0, 0) 28%, rgb(0, 0, 0) 100%);
        z-index: -1;
    }
    &:hover {
        color: #000;
        &:after {
            transform: scale(5);
        }
    }
}

.btn-disabled {
    background-color: #cccccc;
    font-size: 20px;
    line-height: 24px;
    padding: 16px;
    width: 100%;
    border-radius: 8px;
    border: none;
    color: $secondaryColor;
    font-weight: 600;
}

.btn-black-heavyborder {
    background: #ffffff;
    border: 3px solid #171717;
    border-bottom: 6px solid #171717;
    border-right: 6px solid #171717;
    border-radius: 10px;
    padding: 9px 24px 12px 24px;
    font-weight: 600;
    text-transform: capitalize;
    // transition: all in 0.3s ease-out;
    position: relative;
    overflow: hidden;
    z-index: 10;
    // &:hover {
    //     background-color: #171717;
    //     color: #fff;
    // }
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: scale(0);
        transition: transform 0.3s ease-in;
        // mix-blend-mode: difference;
        background: radial-gradient( circle at center, #000 28%, #fff 28%, #fff 100%);
        // background-color: #000;
        z-index: -1;
    }
    &:hover {
        color: #fff;
        &:after {
            transform: scale(5);
        }
    }
}

.btn-inputbtn {
    background: $primaryColor;
    border: none;
    border-radius: 76px;
    color: $secondaryColor;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    position: relative;
    overflow: hidden;
    z-index: 10;
}