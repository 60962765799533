.readytostart_wrapper {
    background-color: $b-color;
    .ready-to-start {
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .readytostart_left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h2 {
                margin-bottom: 8px;
            }
            p {
                font-family: $hellix;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                color: #4a4a4a;
                text-align: center;
                margin-bottom: 24px;
            }
        }
    }
    @media only screen and (min-width: 860px) {
        .ready-to-start {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .readytostart_left {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                h2 {
                    margin-bottom: 16px;
                    width: 100%;
                    font-family: $hellix;
                }
                p {
                    margin-bottom: 0px;
                    font-family: $hellix;
                }
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .ready-to-start {
        padding: 60px 80px !important;
    }
}

@media only screen and (min-width: 1280px) {
    .ready-to-start {
        padding: 60px 100px !important;
    }
}