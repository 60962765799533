.signup_wrapper {
    background-color: #f2f2f2;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .signup_container {
        border-radius: 20px;
        width: 415px;
        background-color: #fafafa;
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .signup_logo {
            width: 110px;
            margin-bottom: 16px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .signup_heading {
            color: $primaryColor;
            margin-bottom: 6px;
            text-align: center;
        }
        h1 {
            font-weight: 700;
            font-size: 31px;
            line-height: 44px;
            margin-bottom: 16px !important;
        }
        h2 {
            font-weight: 600;
            font-size: 25px;
            line-height: 35px;
        }
        h3 {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
        }
        .about-subheading {
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #595959;
            margin-bottom: 32px;
            text-align: center;
        }
        .signup_subheading {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: $primaryColor;
            margin-bottom: 46px;
        }
        .signup_form {
            width: 100%;
            .signup_inputfield {
                width: 100%;
                margin-bottom: 16px;
                position: relative;
                &:last-child {
                    margin-bottom: 0px;
                }
                .signup_smselect {
                    position: absolute;
                    width: auto !important;
                    select {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        text-indent: 1px;
                        text-overflow: '';
                    }
                    .select_input {
                        height: 54px !important;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                    }
                }
                .related_inputfield {
                    padding-left: 68px;
                }
                .select_input,
                input {
                    width: 100%;
                    border: 1px solid $primaryColor;
                    border-radius: 4px;
                    padding: 15px 8px;
                    outline: none;
                    &::placeholder {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 18px;
                        color: $gray-3;
                    }
                }
                select option:not(:disabled) {
                    color: $primaryColor;
                }
                select:not(:checked) {
                    color: $gray-3;
                }
            }
            .signup_checkcontent {
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                color: $primaryColor;
                display: flex;
                gap: 12px;
                margin-bottom: 46px;
                input[type='checkbox'] {
                    width: 24px;
                }
            }
            .signup_togglebutton {
                background-color: $primaryColor;
                color: $secondaryColor;
                padding: 14px;
                position: absolute;
                right: 0px;
                border-radius: 0px 4px 4px 0px;
                border: none;
                width: 100%;
                height: 100%;
                width: 50px;
                padding-top: 16px;
            }
        }
        .btn-lgbutton {
            margin-top: 16px;
        }
        .btn-black-outlined {
            width: 100%;
            margin-top: 16px;
        }
    }
}