.resend-code {
    margin-top: 39px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    text-decoration-line: underline;
    color: #211e27;
}

.confirmation-code {
    margin-top: 29px;
    input {
        background: #e9e9e9;
        border: 1px solid #f1f1f1;
        border-radius: 8px;
        width: 62px;
        height: 60px;
        text-align: center;
        margin-right: 6px;
        outline: none;
        border: none;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        color: $primaryColor;
    }
     ::--input-placeholder {
        text-align: center;
        // padding-left: 10px;
        font-family: ApercuPro-Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #212121;
        @media only screen and (min-width: 1280px) {
            padding-left: 10px;
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}