.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    // margin: 40px 0px 0px 0px;
    background-color: #ffffff;
    @media only screen and (min-width: 560px) {
        padding-bottom: 20px;
    }
    .powered_by {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }
    .welcomepage__desc {
        font-weight: 400;
        font-size: 9px;
        line-height: 11px;
        text-align: center;
        color: #646268;
        // margin-top: 16px;
    }
    p {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #211e27;
        margin: 0px 8px 0px 0px;
    }
    img {
        width: 55px;
        object-fit: cover;
    }
    .learnmore {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        text-decoration-line: underline;
        color: #211e27;
        margin-top: 16px;
    }
}